<template>
    <div class="control">
        <div class="tab-control">
            <van-tabs v-model:active="tabActive" animated sticky>
                <van-tab title="全部">
                    <div class="order-list">
                        <div class="order-item flex-between" v-for="(item,index) in dataList" :key="item.id + ''+index">
                            <div class="order-item-left">
                                <van-skeleton title :loading="loading" title-width="120" style="margin-bottom: 12px">
                                    <div class="content-1 flex-align">
                                        <img
                                            src="https://keyang2.tuzuu.com/uuo/ydj/9fdecf8c1462c5c3a1d1aa443d92d283.png"/>
                                        <span>{{ item.card_name }}</span>
                                    </div>
                                </van-skeleton>
                                <div class="content-2">
                                    <van-skeleton title :loading="loading" title-width="180">
                                        申请时间:{{ item.reserve_date }}
                                    </van-skeleton>
                                </div>
                            </div>
                            <div
                                :class="{'order-item-right':true, 'warning':item.reserve_status === 1,'success':item.reserve_status===2,'info':item.reserve_status ===3}">
                                <van-skeleton title :loading="loading" title-width="50">
                                    <span v-if="item.reserve_status === 1">预约中</span>
                                    <span v-else-if="item.reserve_status === 2">已完成</span>
                                    <span v-else-if="item.reserve_status === 3">已取消</span>
                                </van-skeleton>
                            </div>
                        </div>
                        <van-empty description="暂无数据" v-if="dataList.length === 0"/>
                    </div>
                </van-tab>
                <van-tab title="预约中">
                    <div class="order-list">
                        <div class="order-item flex-between" v-for="(item,index) in getFilterList(1)"
                             :key="item.id + ''+index">
                            <div class="order-item-left">
                                <van-skeleton title :loading="loading" title-width="120" style="margin-bottom: 12px">
                                    <div class="content-1 flex-align">
                                        <img
                                            src="https://keyang2.tuzuu.com/uuo/ydj/9fdecf8c1462c5c3a1d1aa443d92d283.png"/>
                                        <span>{{ item.card_name }}</span>
                                    </div>
                                </van-skeleton>
                                <div class="content-2">
                                    <van-skeleton title :loading="loading" title-width="180">
                                        申请时间:{{ item.reserve_date }}
                                    </van-skeleton>
                                </div>
                            </div>
                            <div
                                :class="{'order-item-right':true, 'warning':item.reserve_status === 1,'success':item.reserve_status===2,'info':item.reserve_status ===3}">
                                <van-skeleton title :loading="loading" title-width="50">
                                    <span v-if="item.reserve_status === 1">预约中</span>
                                    <span v-else-if="item.reserve_status === 2">已完成</span>
                                    <span v-else-if="item.reserve_status === 3">已取消</span>
                                </van-skeleton>
                            </div>
                        </div>
                        <van-empty description="暂无数据" v-if="getFilterList(1).length === 0"/>
                    </div>
                </van-tab>
                <van-tab title="已完成">
                    <div class="order-list">
                        <div class="order-item flex-between" v-for="(item,index) in getFilterList(2)"
                             :key="item.id + ''+index">
                            <div class="order-item-left">
                                <van-skeleton title :loading="loading" title-width="120" style="margin-bottom: 12px">
                                    <div class="content-1 flex-align">
                                        <img
                                            src="https://keyang2.tuzuu.com/uuo/ydj/9fdecf8c1462c5c3a1d1aa443d92d283.png"/>
                                        <span>{{ item.card_name }}</span>
                                    </div>
                                </van-skeleton>
                                <div class="content-2">
                                    <van-skeleton title :loading="loading" title-width="180">
                                        申请时间:{{ item.reserve_date }}
                                    </van-skeleton>
                                </div>
                            </div>
                            <div
                                :class="{'order-item-right':true, 'warning':item.reserve_status === 1,'success':item.reserve_status===2,'info':item.reserve_status ===3}">
                                <van-skeleton title :loading="loading" title-width="50">
                                    <span v-if="item.reserve_status === 1">预约中</span>
                                    <span v-else-if="item.reserve_status === 2">已完成</span>
                                    <span v-else-if="item.reserve_status === 3">已取消</span>
                                </van-skeleton>
                            </div>
                        </div>
                        <van-empty description="暂无数据" v-if="getFilterList(2).length === 0"/>
                    </div>
                </van-tab>
                <van-tab title="已取消">
                    <div class="order-list">
                        <div class="order-item flex-between" v-for="(item,index) in getFilterList(3)"
                             :key="item.id + ''+index">
                            <div class="order-item-left">
                                <van-skeleton title :loading="loading" title-width="120" style="margin-bottom: 12px">
                                    <div class="content-1 flex-align">
                                        <img
                                            src="https://keyang2.tuzuu.com/uuo/ydj/9fdecf8c1462c5c3a1d1aa443d92d283.png"/>
                                        <span>{{ item.card_name }}</span>
                                    </div>
                                </van-skeleton>
                                <div class="content-2">
                                    <van-skeleton title :loading="loading" title-width="180">
                                        申请时间:{{ item.reserve_date }}
                                    </van-skeleton>
                                </div>
                            </div>
                            <div
                                :class="{'order-item-right':true, 'warning':item.reserve_status === 1,'success':item.reserve_status===2,'info':item.reserve_status ===3}">
                                <van-skeleton title :loading="loading" title-width="50">
                                    <span v-if="item.reserve_status === 1">预约中</span>
                                    <span v-else-if="item.reserve_status === 2">已完成</span>
                                    <span v-else-if="item.reserve_status === 3">已取消</span>
                                </van-skeleton>
                            </div>
                        </div>
                        <van-empty description="暂无数据" v-if="getFilterList(3).length === 0"/>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
import wxShare from "@/wechat/weChatTile";

export default {
    data() {
        return {
            tabActive: 0,
            loading: true,
            dataList: [{}, {}, {}, {}, {}]
        }
    },
    created() {
        axios.get("/ydj/get_my_reserve").then((res) => {
            this.dataList = res.data || []
            this.loading = false
        })
    },
    mounted() {
        wxShare({
            title: '一站式健康管理服务平台',
            desc:'康来家园，中国健康管理领导者',
            link:window.location.origin + '/ydj',
        })
    },
    computed: {
        getFilterList() {
            return (type) => {
                return this.dataList.filter(item => item.reserve_status === type)
            }

        }
    }
}
</script>

<style scoped lang="less">
.control {
    min-height: 100vh;
    background: #F8F8F8;

    :deep(.tab-control) {

        .van-tabs__wrap {
            height: 60px;
        }

        .van-tabs__line {
            background: #369867;
            height: 3px;
        }

        .van-tab--line {
            font-size: 15px;
            color: #666666;
            transform: translateY(5px);
            line-height: 21px;
        }

        .van-tab--active {
            font-size: 16px;
            font-weight: bold;
            color: #369867;
        }

        .van-tabs__content {
            flex: 1;
            height: 100%;

        }
    }

    .order-list {
        padding: 16px 13px;

        .order-item {
            background: #FFFFFF;
            border-radius: 6px;
            padding: 24px 15px;

            & + .order-item {
                margin-top: 10px;
            }

            .van-skeleton {
                padding: 0;
            }

            .order-item-left {
                .content-1 {
                    font-size: 15px;
                    font-weight: bold;
                    color: #232323;
                    line-height: 21px;

                    img {
                        width: 23px;
                        margin-right: 6px;
                    }
                }

                .content-2 {
                    font-size: 13px;
                    color: #999999;
                    line-height: 19px;
                    padding-left: 30px;
                    margin-top: 7px;
                }
            }

            .order-item-right {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;

                &.warning {
                    color: #FF9557;
                }

                &.success {
                    color: #369867;
                }

                &.info {
                    color: #999999;
                }
            }
        }
    }
}
</style>